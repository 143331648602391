<!--  

	MhView
	======
	Wrapper for <router-view></router-view> with 
	fade transitions and loading spinner if needed
	keep scroll postions at browsers back/forward button navigation
			
	property "state" enum: 
		"", 		> nothing
		"loading",	> shows the spinner 
		"loaded"	> hides the spinner again
		
	property "state" can be changed from child component via $emit:
		this.$emit('stateChanged', 'loading') // before axios fetch
		this.$emit('stateChanged', 'loaded')  // after axios fetch
		
	2019-05-21	first refactored commit

-->

<template>
	<div class="mhView" :state="''" :class="classes">
		<MhSpinner class="mhView__loadingSpinner"></MhSpinner>
		
		<!-- 
			<div >
			</div>
			<keep-alive>
			</keep-alive>
		-->
		<transition name="fade" XXXduration="1000" mode="out-in" XXXenter="enterTransition">
			<router-view 
				ref="scrolled"
				class="mhView__inner" 
				:key="$route.fullPath" 
			/>
		</transition>
	</div>
</template>

<script>
	// @ is an alias to /src 
	import MhSpinner from '@/components/MhSpinner/MhSpinner.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'MhView',
		components: {
			MhSpinner
		},			
		data() {
			return {
				state : 'loading',
				scrollTo : 0,
				scroll_positions: {},
				currentScrollTop : 0,
				debugLog : false,
				classes : '',
			}
		},
		watch: {
			'$route.path' ( to, from ){	// resets the state if route changes
				this.state = ''	
			},
		},
		computed: {
			XXXelmClasses(){
				let classes = []
				
				// {'mhView--isLoading' : viewIsLoading, 'mhView--loaded' : !viewIsLoading}
				
				if( this.state == 'loading' ){
					classes.push('mhView--state-loading')					
				}
				if( this.state == 'loaded' ){
					this.$nextTick(() => {
						//alert(12)
						classes.push('mhView--state-loaded')
						return classes						
					})	
					//setTimeout( () => {}, 100)
				}
				
				return classes
			}
		},
		methods: {
			scroll_container( scroll ) {
				const scrollerElm = this.$refs.scrolled.$el
				
				scrollerElm.scrollTop = scroll
			},
			enterTransition( elm ){
				const scrollTo = this.scrollTo
				//const transitionDuration = window.getComputedStyle( elm )['transition-duration']
				const debugLog = this.debugLog
				
				if( debugLog ){
					console.log('')
					console.group('enter')
					console.log('elm:', elm)
					//console.log('transitionDuration:', transitionDuration)
					console.log('scrollTo:', scrollTo)	
					console.groupEnd('enter')
				}
				
				setTimeout( () => {
					this.scroll_container( scrollTo )					
				}, 50)
				
				//elm.style.backgroundColor = 'red'
			},
		},
		created() {
			EventBus.$on('stateChanged', newState => {
				this.state = newState
				
				if( this.state == 'loading' ){
					this.classes = 'mhView--state-loading'
				}
				if( this.state == 'loaded' ){
					this.$nextTick(() => {
						this.classes = 'mhView--state-loaded'
					})	
					/*
					//setTimeout( () => {}, 100)
					*/
				}				
			})			
		},
		mounted() {
			
			// keep scroll postions at browsers back/forward button navigation
			// 
			// see: https://github.com/vuejs/vue-router/issues/1187
			
			function fullPath(){
				return window.location.pathname + window.location.search
			}
			
			// beforeEach
			setTimeout(()=> {
				this.$router.beforeEach( (to, from, next ) => {
					const scrollerElm = this.$refs.scrolled.$el
					const debugLog = this.debugLog
					
					this.currentScrollTop = scrollerElm.scrollTop
					
					if( debugLog ){
						console.log('')
						console.group('beforeEach')
						console.log('from:', from.fullPath)					
						console.log('to:', to.fullPath)
						console.log('scrollerElm:', scrollerElm)					
						console.log('currentScrollTop:', this.currentScrollTop)
					}				
					
					this.scroll_positions[from.fullPath] = this.currentScrollTop
					
					if( debugLog ){
						console.log('scroll_positions:', this.scroll_positions)
						console.groupEnd('beforeEach')
					}
					
					next()
				})			
			}, 50)			
			
			// afterEach
			this.$router.afterEach( (to, from) => {
				const scrollTo = this.scrollTo
				const debugLog = this.debugLog
				
				if( debugLog ){
					console.log('')
					console.group('afterEach')
					console.log('current scrollTo:', scrollTo)
					console.log('reset to 0')
					console.log('scroll_positions:', this.scroll_positions)
					console.groupEnd('afterEach')
				}
				
				this.scrollTo = 0
			})			
			
			// onpopstate
			window.onpopstate = (event) => {
				const old_pos = this.scroll_positions[fullPath()]
				const debugLog = this.debugLog
				
				if( debugLog ){
					console.log('')
					console.group('onpopstate')	
					console.log('fullPath:', fullPath())					
					console.log('old_pos:', old_pos)
				}
				
				if( old_pos ){
					if( debugLog ) console.log('has old_pos > scroll_container('+old_pos+')')						
					this.scrollTo = old_pos
				}else{
					if( debugLog ) console.log('no old_pos > do nothing')
				}
				
				if( debugLog ) console.groupEnd('onpopstate')
			}
			
			// enter
		},
	}
</script>

<style lang="less">	
	.mhView {
		//background-color: grey;
		//transition-duration: 3s !important;

		@duration : 0.3s;

		.mhGrid--home .lubokContentModule,
		.mhGrid--page .lubokContentModule,
		.mhGrid--page .lubokSubNav,
		.mhGrid--productsTeaser {
			transition: 
				padding-top @duration ease-in @duration*0.3,
				
				grid-row-gap @duration*0.6 ease-in @duration*0.5,
				margin @duration*0.6 ease-in @duration*0.5,
				
				opacity @duration*1.5 ease-in @duration*0.3;
		}

		&--state-loading {
			
			.mhGrid--home .lubokContentModule,
			.mhGrid--page .lubokContentModule {
				margin-bottom: 6rem;
				opacity: 0 !important;
				
				&:first-child {
					padding-top: 6rem; 
				}			
			}	

			.mhGrid--productsTeaser {
				padding-top: 6rem; 
				grid-row-gap: 9rem; 
				opacity: 0 !important;
			}

		}
		&--state-loaded {
			
			.mhGrid--productsTeaser {
				//padding-top: 0rem; 
				//grid-row-gap: 1rem; 
				//opacity: 1;
			}
			
			.mhGrid--home .lubokContentModule,
			.mhGrid--page .lubokContentModule {
				//margin-bottom: 0rem;
				//opacity: 1;		
			}
			
		}
		
	}
	.mhView__loadingSpinner {}
</style>
